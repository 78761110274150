import ApproveView from "../../components/Approve/ApproveView";

function ApproveDetail() {
  return (
    <>
      <ApproveView />
    </>
  );
}

export default ApproveDetail;
