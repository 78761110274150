import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useEffect, useState } from "react";
import {
  DropDown,
  StatusAgg,
  StyledBodyNoPadding,
  StyledHead1,
  StyledHead2,
} from "../../styles/theme";
import { ErrorHandler } from "../../system/ApiService";
import StatisticsApi from "../../system/api/StatisticsApi";
import { AggState, AggTotal } from "../../system/types/Statistics";
import InfoIcon from "@mui/icons-material/Info";
interface AsmtStatusProps {
  year: string;
  onChangeYear: (arg: any) => void;
  setState: any;
}

function AsmtStatus({ year, onChangeYear, setState }: AsmtStatusProps) {
  const [data, setData] = useState<AggTotal>();
  const [stateData, setStateData] = useState<AggState>();

  useEffect(() => {
    StatisticsApi.GetTotalStatistics(year)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });

    StatisticsApi.GetStateStatistics(year)
      .then((res) => {
        setStateData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [year]);

  const onChangeState = (state: string) => {
    setState(state);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const borderBar = {
    backgroundColor: "#F7F8FA",
    width: "100%",
    margin: "20px 0px",
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container alignItems="center">
        <hr style={borderBar} />
        <Typography variant="body1" fontWeight="bold">
          1인 1과제 현황
        </Typography>
        <div style={{ marginLeft: "10px", marginTop: "4px" }}>
          <DropDown>
            <DropDownListComponent
              cssClass="e-outline"
              placeholder="년도"
              dataSource={["2022", "2023", "2024", "2025"]}
              onChange={onChangeYear}
              value={year}
              width={"80px"}
              style={{ minHeight: "29px" }}
            ></DropDownListComponent>
          </DropDown>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <StatusAgg
            onClick={() => {
              onChangeState("전체");
            }}
          >
            {" "}
            전사 진행과제 총계 : {stateData?.all}
            {"개 "}
          </StatusAgg>
          <StatusAgg
            onClick={() => {
              onChangeState("진행");
            }}
          >
            ( 진행 {stateData?.runningCount} ,
          </StatusAgg>
          <StatusAgg
            onClick={() => {
              onChangeState("완료");
            }}
          >
            {" "}
            완료 {stateData?.completionCount} ,
          </StatusAgg>

          <StatusAgg
            onClick={() => {
              onChangeState("등록대기");
            }}
          >
            {" "}
            등록대기 {stateData?.waitingCount} )
          </StatusAgg>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center">참여대상</StyledHead1>
                <StyledHead1 align="center" colSpan={4}>
                  참여
                </StyledHead1>
                <StyledHead1 align="center" colSpan={2}>
                  미참여
                </StyledHead1>
                <StyledHead1 align="center" colSpan={3}>
                  성과급
                </StyledHead1>
              </TableRow>
              <TableRow>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">참여율(%)</StyledHead2>
                <StyledHead2 align="center">PM(명)</StyledHead2>
                <StyledHead2 align="center">멤버(명)</StyledHead2>
                <StyledHead2 align="center">인원수(명)</StyledHead2>
                <StyledHead2 align="center">미참여율(%)</StyledHead2>
                <StyledHead2 align="center">
                  인원수(명)
                  <Tooltip title="퇴사자 포함" placement="right">
                    <InfoIcon
                      fontSize="small"
                      color="action"
                      sx={{ marginTop: "-3px", marginLeft: "3px" }}
                    />
                  </Tooltip>
                </StyledHead2>
                <StyledHead2 align="center">금액(원)</StyledHead2>
                <StyledHead2 align="center">평균(원)</StyledHead2>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledBodyNoPadding align="center">
                  {data?.totalUsers}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.participants}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.participantRatio}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.projectManagers}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.members}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.nonparticipants}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.nonparticipantRatio}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.actualParticipants}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.totalMeritPay
                    .toLocaleString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </StyledBodyNoPadding>
                <StyledBodyNoPadding align="center">
                  {data?.avgMeritPay
                    .toLocaleString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </StyledBodyNoPadding>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default AsmtStatus;
