import LockIcon from "@mui/icons-material/Lock";
import { Grid, Pagination, Typography } from "@mui/material";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids/src";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { QnaApi } from "../../../system/api/QnaApi";
import { useUserState } from "../../../system/context/UserContext";
import { InitQna, InitQnaContent } from "../../../system/types/initObject";
import { GuideRoutes, Qna, QnaContent } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import { useRecoilState } from "recoil";
import { pageState } from "../../../system/atoms";

function QnATable() {
  const user = useUserState();
  const history = useHistory();
  const [data, setData] = useState<Qna>(InitQna);
  const [dataDetail, setDataDetail] = useState<QnaContent>(InitQnaContent);
  const [page, setPage] = useRecoilState(pageState);
  const onWriteQnA = () => {
    history.push({
      pathname: `${GuideRoutes.qnaRoot}/edit`,
    });
  };

  useEffect(() => {
    QnaApi.getQnaAll(page, 10, "createdAt,desc")
      .then((res) => {
        setDataDetail(res.data.articles);
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [page]);

  const onPageChange = (args: any, value: number) => {
    setPage(value - 1);
  };

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      if (selectedrecords[0].secret) {
        if (
          selectedrecords[0].writer.name === user.name ||
          user.roles.includes("Admin")
        ) {
          history.push({
            pathname: `${GuideRoutes.qnaRoot}/detail/${selectedrecords[0].id}`,
          });
        } else {
          alert("해당 글은 작성자와 관리자만 확인 가능합니다.");
        }
      } else {
        history.push({
          pathname: `${GuideRoutes.qnaRoot}/detail/${selectedrecords[0].id}`,
        });
      }
    }
  };

  const titleTemplate = useCallback((props: any): any => {
    let titlePrivate = props.secret;
    let titleContent = props.title;
    if (titlePrivate) {
      return (
        <>
          {titleContent} <LockIcon fontSize="small" />
        </>
      );
    } else {
      return <>{titleContent}</>;
    }
  }, []);

  const indexTemplate = useCallback((args: any): any => {
    return <>{parseInt(args.index) + 1}</>;
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TitleDiv title="문의하기(QnA)"></TitleDiv>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right", marginTop: 1 }}>
        <CustomButton onClick={onWriteQnA} iconCss="e-icons e-plus">
          작성
        </CustomButton>
      </Grid>
      <Grid item xs={12}>
        <GridComponent
          dataSource={dataDetail}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              template={indexTemplate}
              headerText="No."
              textAlign="Center"
              width="50"
            />
            <ColumnDirective
              field="qnaCls"
              headerText="문의 분류"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="title"
              headerText="제목"
              headerTextAlign="Center"
              template={titleTemplate}
              width="350"
            />
            <ColumnDirective
              field="writer.name"
              headerText="작성자"
              textAlign="Center"
              width="100"
            />
          </ColumnsDirective>
          <Inject services={[Sort]} />
        </GridComponent>
      </Grid>
      <Grid item xs={6}>
        <Pagination
          count={data.totalPages}
          page={page + 1}
          onChange={onPageChange}
          showFirstButton
          showLastButton
          defaultPage={10}
          boundaryCount={2}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign={"right"}>
          <span>
            {page + 1} of {data.totalPages} pages
          </span>
          <span>({data.totalElements} items)</span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default QnATable;
