import { Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PRIMARY } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { InternApi } from "../../../system/api/InternApi";
import { useUserState } from "../../../system/context/UserContext";
import { InternWorkDetail } from "../../../system/types/InternWork";
import { InitInternWorkDetail } from "../../../system/types/initObject";
import { AsmtWorkRoutes } from "../../../system/types/type";
import CommentTable from "../../Common/Comment/CommentTable";
import TitleDiv from "../../Common/TitleDiv";
import ReportTable from "../../Note/Report/ReportTable";
import InternComplete from "./InternComplete";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface Params {
  id: string;
}

function InternView() {
  const { id } = useParams<Params>();
  const internId = parseInt(id);
  const user = useUserState();
  const [value, setValue] = useState(1);
  const history = useHistory();
  const [data, setData] = useState<InternWorkDetail>(InitInternWorkDetail);
  const [commentnumber, setCommentNumber] = useState("");
  const Loading = useLoadingDispatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (internId) {
      Loading({ type: "LOADING" });
      InternApi.getInternWorkDetail(internId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    } else {
      alert("잘못된 접근입니다.");
      history.push(AsmtWorkRoutes.intern);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internId, commentnumber]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="인턴 과제 기획서"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={value}
          textColor="inherit"
          TabIndicatorProps={{
            style: { background: PRIMARY },
          }}
          onChange={handleChange}
          style={{ marginBottom: "10px" }}
        >
          <Tab label={<span>기획서</span>} value={1} />
          {data.participants.findIndex((x) => x.name === user.name) !== -1 ||
          user.roles.includes("Admin") ? (
            <Tab label={<span>보고서</span>} value={4} />
          ) : null}
        </Tabs>
        {value === 1 && (
          <>
            <InternComplete data={data} type={"View"}></InternComplete>
            <CommentTable
              cType={"asmtWork"}
              data={data}
              setCommentNumber={setCommentNumber}
            />
          </>
        )}
        {value === 4 && (
          <ReportTable
            parent={{
              parentId: internId,
              parentTitle: data.title,
              workType: data.workType,
              participant: data.participants,
            }}
            admin={
              data.participants.findIndex((x) => x.name === user.name) === -1 &&
              user.roles.includes("Admin")
                ? true
                : false
            }
          ></ReportTable>
        )}
      </Grid>
    </Grid>
  );
}

export default InternView;
