import { Grid } from "@mui/material";
import {
  Filter,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids/src";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import ReportApi from "../../../system/api/ReportApi";
import { ReportList } from "../../../system/types/Report";
import { IlocationIntern, ReportRoutes } from "../../../system/types/type";

interface ReportProps {
  parent: IlocationIntern;
  admin: boolean;
}
function ReportTable({ parent, admin }: ReportProps) {
  const pageSettings: PageSettingsModel = { pageSize: 10 };
  const history = useHistory();
  const [data, setData] = useState<ReportList[]>([]);

  let grid: any;
  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      history.push(`${ReportRoutes.root}/detail/${selectedrecords[0].id}`);
    }
  };

  useEffect(() => {
    ReportApi.getReportList(parent.parentId)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, [parent.parentId]);

  const onWriteReport = () => {
    history.push({
      pathname: `${ReportRoutes.root}/edit`,
      state: parent,
    });
  };

  return (
    <Grid container>
      {!admin && (
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <CustomButton onClick={onWriteReport} iconCss="e-icons e-plus">
            보고서 작성
          </CustomButton>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <GridComponent
          dataSource={data}
          allowPaging={true}
          pageSettings={pageSettings}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="title"
              headerText="보고서명"
              headerTextAlign="Center"
              width="300"
            />
            <ColumnDirective
              field="startReportTime"
              headerText="보고예정일"
              textAlign="Center"
              width="100"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default ReportTable;
