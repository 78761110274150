import {
  Grid as MaterialGrid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../../styles/table.css";
import {
  CustomButton,
  DataGridContent,
  StyledBodyPa5,
  StyledHead,
  StyledListComponent,
  TextNoMargin,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import {
  ASMT_CLASSIFICATION,
  ASMT_STATUS,
  ASMT_STATUS_ADMIN,
  ASMT_STATUS_GRID_ADMIN,
  SEARCH_NAME,
  SearchYears,
} from "../../../system/Constants";
import { CommonWorkApi } from "../../../system/api/CommonApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AsmtList } from "../../../system/types/AsmtWork";
import { InitAsmtList } from "../../../system/types/initObject";
import { AsmtWorkRoutes, WorkUser } from "../../../system/types/type";
import TitleDiv from "../../Common/TitleDiv";
import { useUserState } from "../../../system/context/UserContext";
import { AxiosResponse } from "axios";
import { searchDataState, searchDetailState } from "../../../system/atoms";
import { useRecoilState } from "recoil";
import StatusChip from "../../Common/StatusChip";
import AdminApi from "../../../system/api/AdminApi";
interface AsmtTableProps {
  main?: boolean;
  state: string;
  setState: any;
  year: string;
  setYear: any;
}

function AsmtTable({ main, state, setState, year, setYear }: AsmtTableProps) {
  const history = useHistory();
  const [searchData, setSearchData] = useRecoilState(searchDataState);
  const [searchDetail, setSearchDetail] = useRecoilState(searchDetailState);
  const [data, setData] = React.useState<AsmtList>(InitAsmtList);
  const fields: object = { text: "text", value: "value" };
  const Loading = useLoadingDispatch();
  const user = useUserState();
  const [changeTime, setChangeTime] = useState(new Date());

  // useMemo(() => {
  //   if (state !== "" || state !== undefined) {
  //     setSearchData({ ...searchData, status: state });
  //   }
  //   if (year !== "" || year !== undefined) {
  //     setSearchData({ ...searchData, year: year });
  //   }
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state,year]);

  useEffect(() => {
    setSearchData({ ...searchData, year: year });
  }, [year]);

  useEffect(() => {
    Loading({ type: "LOADING" });
    CommonWorkApi.GetAsmtWorkList("full-timer", "all", searchData)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [Loading, searchData, changeTime]);

  const downloadFile = () => {
    CommonWorkApi.GetAsmtWorkList("full-timer", "all", {
      ...searchData,
      isFile: true,
      size: 100000,
    })
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  let grid: Grid | null;
  // const rowSelected = (args: any) => {
  // if (grid) {
  // if (args.previousRowIndex === 2) {
  // } else {
  //   const selectedrecords: any = grid.getSelectedRecords();
  //   history.push({
  //     pathname: `${AsmtWorkRoutes.root}/detail/${selectedrecords[0].id}`,
  //   });
  // }
  //}
  //};

  const moveToDetailPage = (id: number) => {
    history.push({
      pathname: `${AsmtWorkRoutes.root}/detail/${id}`,
    });
  };

  const onPageChange = (args: any, value: number) => {
    setSearchData({ ...searchData, page: value - 1 });
  };

  const onClickSearch = () => {
    setSearchData({
      ...searchData,
      searchType: searchDetail.type,
      searchWord: searchDetail.word,
      page: 0,
    });
  };

  const onChangeSearch = (args: any) => {
    setState("");
    setSearchDetail({
      ...searchDetail,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChange = (args: any) => {
    setSearchData({
      ...searchData,
      [args.target.name]: args.target.value,
      page: 0,
    });
    if (args.target.name === "year") {
      setYear(args.target.value);
    }
  };

  const partTemplate = useCallback((props: any): any => {
    let pmList: WorkUser[] = props.participants.filter(
      (x: WorkUser) => x.projectManager
    );
    return (
      <DataGridContent onClick={() => moveToDetailPage(props.id)}>
        {pmList.map((x) => x.department).join("/")}
      </DataGridContent>
    );
  }, []);

  const pmTemplate = useCallback((props: any): any => {
    let pmList: WorkUser[] = props.participants.filter(
      (x: WorkUser) => x.projectManager
    );
    if (pmList.length > 2) {
      return (
        <DataGridContent onClick={() => moveToDetailPage(props.id)}>
          {pmList[0].name + " 외 " + (pmList.length - 1) + "명"}
        </DataGridContent>
      );
    } else {
      return (
        <DataGridContent onClick={() => moveToDetailPage(props.id)}>
          {pmList.map((x) => x.name).join(",")}
        </DataGridContent>
      );
    }
  }, []);

  const mentorTemplate = useCallback((props: any): any => {
    let mentorList: WorkUser[] = props.participants.filter(
      (x: WorkUser) => x.mentor
    );
    if (mentorList.length > 2) {
      return (
        <DataGridContent onClick={() => moveToDetailPage(props.id)}>
          {mentorList[0].name + " 외 " + (mentorList.length - 1) + "명"}
        </DataGridContent>
      );
    } else {
      return (
        <DataGridContent onClick={() => moveToDetailPage(props.id)}>
          {mentorList.map((x) => x.name).join(",")}
        </DataGridContent>
      );
    }
  }, []);

  const titleTemplate = useCallback((props: any): any => {
    let AsmtTitle = props.title;
    return (
      <DataGridContent onClick={() => moveToDetailPage(props.id)}>
        {AsmtTitle}
      </DataGridContent>
    );
  }, []);

  const participantCntTemplate = useCallback((props: any): any => {
    let participantCnt = props.participantCnt;
    return (
      <DataGridContent onClick={() => moveToDetailPage(props.id)}>
        {participantCnt}
      </DataGridContent>
    );
  }, []);

  const classificationTemplate = useCallback((props: any): any => {
    let classification = props.classification;
    return (
      <DataGridContent onClick={() => moveToDetailPage(props.id)}>
        {classification}
      </DataGridContent>
    );
  }, []);

  const meritTemplate = useCallback((props: any): any => {
    let checkmeritPay = props.meritPay
      .toLocaleString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return (
      <DataGridContent onClick={() => moveToDetailPage(props.id)}>
        {checkmeritPay}
      </DataGridContent>
    );
  }, []);

  let dropDownListObject: any;
  const statusRef = useRef<any>([]);

  const onChangeStatus = (id: number, e: any, idx: number) => {
    const isConfirm = window.confirm(
      `해당 과제의 진행상태를 ${e.value}(으)로 변경하시겠습니까?`
    );
    if (isConfirm) {
      AdminApi.UpdateAdminStatus({
        workId: id,
        status: e.value,
      })
        .then(() => {
          alert("진행상태가 변경되었습니다.");
          setChangeTime(new Date());
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
          statusRef.current[idx].value = null;
        });
    } else {
      statusRef.current[idx].value = null;
    }
  };

  const statusTemplate = useCallback((props: any): any => {
    let AsmtStatus = props.status;
    let AsmtSubStatus = props.subStatus;
    if (user.roles.includes("Admin")) {
      return (
        <DataGridContent>
          <DropDownListComponent
            ref={(scope) => {
              statusRef.current[props.index] = scope;
            }}
            id="ddlelement"
            dataSource={ASMT_STATUS_GRID_ADMIN}
            placeholder={
              props.subStatus ? `${AsmtStatus}-${AsmtSubStatus}` : AsmtStatus
            }
            onChange={(e: any) => onChangeStatus(props.id, e, props.index)}
          />
        </DataGridContent>
      );
    } else {
      if (props.subStatus) {
        return (
          <>
            {AsmtStatus}-{AsmtSubStatus}
          </>
        );
      } else {
        return (
          <>
            <StatusChip status={AsmtStatus} subStatus=""></StatusChip>
          </>
        );
      }
    }
  }, []);

  return (
    <MaterialGrid container spacing={1}>
      <MaterialGrid item xs={12}>
        {main ? (
          <Typography variant="body1" fontWeight="bold">
            과제 업무
          </Typography>
        ) : (
          <TitleDiv title="과제 업무"></TitleDiv>
        )}
      </MaterialGrid>
      <MaterialGrid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <StyledHead width={"80"} align="center">
                  기간검색
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <StyledListComponent
                    name="year"
                    value={searchData.year}
                    cssClass="e-outline"
                    dataSource={SearchYears}
                    onChange={onChange}
                  ></StyledListComponent>
                </StyledBodyPa5>
                <StyledHead width={"80"} align="center">
                  분류
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="classification"
                    value={searchData.classification}
                    cssClass="e-outline"
                    onChange={onChange}
                    fields={fields}
                    dataSource={ASMT_CLASSIFICATION}
                  ></DropDownListComponent>
                </StyledBodyPa5>
                <StyledHead width={"80"} align="center">
                  진행상태
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="status"
                    value={searchData.status}
                    cssClass="e-outline"
                    onChange={onChange}
                    fields={fields}
                    dataSource={
                      user.roles.includes("Admin")
                        ? ASMT_STATUS_ADMIN
                        : ASMT_STATUS
                    }
                  ></DropDownListComponent>
                </StyledBodyPa5>
              </TableRow>
              <TableRow>
                <StyledHead width={"80"} align="center">
                  검색어
                </StyledHead>
                <StyledBodyPa5 align="center">
                  <DropDownListComponent
                    name="type"
                    cssClass="e-outline"
                    value={searchDetail.type}
                    onChange={onChangeSearch}
                    dataSource={SEARCH_NAME}
                  ></DropDownListComponent>
                </StyledBodyPa5>
                <StyledBodyPa5 align="center" colSpan={4}>
                  <MaterialGrid
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MaterialGrid item xs={11}>
                      <TextNoMargin>
                        <TextBoxComponent
                          placeholder="검색어를 입력하세요."
                          cssClass="e-outline"
                          name="word"
                          value={searchDetail.word}
                          onChange={onChangeSearch}
                        ></TextBoxComponent>
                      </TextNoMargin>
                    </MaterialGrid>
                    <MaterialGrid
                      item
                      xs={1}
                      container
                      justifyContent="flex-end"
                    >
                      <CustomButton onClick={onClickSearch}>검색</CustomButton>
                    </MaterialGrid>
                  </MaterialGrid>
                </StyledBodyPa5>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MaterialGrid>
      <MaterialGrid item xs={12} sx={{ textAlign: "right", marginTop: 1 }}>
        <CustomButton onClick={downloadFile} iconCss="e-icons e-download">
          엑셀 다운로드
        </CustomButton>
      </MaterialGrid>
      <MaterialGrid item xs={12}>
        <GridComponent
          dataSource={data?.works}
          // rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="classification"
              headerText="분류"
              headerTextAlign="Center"
              width="70"
              textAlign="Center"
              template={classificationTemplate}
            />
            <ColumnDirective
              field="participants"
              headerText="파트"
              headerTextAlign="Center"
              template={partTemplate}
              width="140"
              textAlign="Center"
            />
            <ColumnDirective
              field="participants"
              headerText="PM"
              headerTextAlign="Center"
              template={pmTemplate}
              width="90"
              textAlign="Center"
            />
            <ColumnDirective
              field="title"
              headerText="과제명"
              headerTextAlign="Center"
              width="400"
              template={titleTemplate}
            />
            <ColumnDirective
              field="participantCnt"
              headerText="참여자수"
              headerTextAlign="Center"
              width="100"
              textAlign="Center"
              template={participantCntTemplate}
            />
            <ColumnDirective
              field="participants"
              headerText="멘토"
              headerTextAlign="Center"
              template={mentorTemplate}
              width="100"
              textAlign="Center"
            />
            <ColumnDirective
              field="status"
              headerText="진행상태"
              headerTextAlign="Center"
              width="150"
              textAlign="Center"
              template={statusTemplate}
            />
            <ColumnDirective
              field="meritPay"
              headerText="&nbsp;&nbsp;&nbsp;&nbsp;성과급"
              headerTextAlign="Center"
              width="120"
              textAlign="Right"
              template={meritTemplate}
            />
          </ColumnsDirective>
        </GridComponent>
      </MaterialGrid>
      <MaterialGrid item xs={6}>
        <Pagination
          count={data.totalPages}
          page={searchData.page + 1}
          onChange={onPageChange}
          showFirstButton
          showLastButton
          defaultPage={10}
          boundaryCount={2}
        />
      </MaterialGrid>
      <MaterialGrid item xs={6}>
        <Typography textAlign={"right"}>
          <span>
            {searchData.page + 1} of {data.totalPages} pages
          </span>
          <span>({data.totalElements} items)</span>
        </Typography>
      </MaterialGrid>
    </MaterialGrid>
  );
}

export default AsmtTable;
