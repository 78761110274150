import { Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { ErrorHandler } from "../../../system/ApiService";
import { QnaApi } from "../../../system/api/QnaApi";
import FileApi from "../../../system/api/fileApi";
import { useUserState } from "../../../system/context/UserContext";
import { QnaContent } from "../../../system/types/type";
import QnAComment from "./QnAComment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CustomButton, QnaAnswer, QnaQuestion } from "../../../styles/theme";
import LockIcon from "@mui/icons-material/Lock";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

export const QnATemp = ({
  data,
  qnaType,
  setQnaType,
  parentData,
  commentState,
  setcommentState,
}: {
  data: QnaContent;
  qnaType: string;
  setQnaType: any;
  parentData: QnaContent;
  commentState: string;
  setcommentState: any;
}) => {
  const user = useUserState();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleDelete = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      if (data.id !== 0) {
        QnaApi.deleteQna(data.id)
          .then(() => {
            setAnchorEl(null);
            setcommentState(new Date());
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          });
      }
    }
  };
  const [recommentState, setRecommentState] = useState("");
  const Loading = useLoadingDispatch();
  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const handleEdit = (id: number) => {
    if (id === data.id) {
      setQnaType("edit" + data.id);
      setAnchorEl(null);
    }
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const recommentCreate = (id: number) => {
    setRecommentState("recomment" + data.id);
  };
  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{ paddingBottom: "10px", alignItems: "center" }}
      >
        <Grid item xs={10}>
          <Typography variant="body1" color={"grey"}>
            {data.qnaType === "답변" ? "답변" : "질문"}
          </Typography>
          <Typography variant="body2" style={{ margin: "5px 0px" }}>
            {data.writer.team + "/" + data.writer.name}
            <Typography
              variant="body2"
              component="span"
              style={{ marginLeft: "10px", color: "#81848B" }}
            >
              {data.createdAt}&nbsp;
            </Typography>
          </Typography>
        </Grid>
        {data.writer.name === user.name && (
          <Grid item xs={2} textAlign={"end"}>
            <IconButton onClick={(event) => handleClick(event)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleEdit(data.id);
                }}
              >
                수정
              </MenuItem>
              <MenuItem onClick={handleDelete}>삭제</MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>
      {data.secret ? (
        <div>
          {data.writer.name === user.name || user.roles.includes("Admin") ? (
            <pre>{data.content}</pre>
          ) : (
            <Typography variant="body1">
              <LockIcon /> 비밀글입니다.{" "}
            </Typography>
          )}
        </div>
      ) : (
        <pre>{data.content}</pre>
      )}
      {data.attachmentFiles.length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" color={"grey"}>
              첨부파일
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {data.attachmentFiles.map((file, idx) => {
              return (
                <div key={idx} style={{ cursor: "pointer" }}>
                  <Typography
                    variant="body2"
                    onClick={() => downloadFile(file.filename, file.filePath)}
                  >
                    {file.filename}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {qnaType === "edit" + data.id ? (
        <>
          <hr style={{ border: "1px solid black", width: "100%" }} />{" "}
          <QnAComment
            parentData={parentData}
            commentId={data.id}
            qnaType="view"
            setQnaType={setQnaType}
            setRecommentState={setRecommentState}
            commentState={commentState}
            setcommentState={setcommentState}
          />
        </>
      ) : (
        ""
      )}
      {data.secret &&
      !(
        data.writer.name === user.name ||
        user.roles.includes("Admin") ||
        parentData.writer.name === user.name
      ) ? (
        <></>
      ) : (
        <CustomButton
          style={{ marginTop: "10px" }}
          onClick={() => {
            recommentCreate(data.id);
          }}
        >
          답글
        </CustomButton>
      )}

      {recommentState === "recomment" + data.id ? (
        <>
          <QnAComment
            parentData={data}
            commentId={0}
            qnaType="view"
            setQnaType={setQnaType}
            setRecommentState={setRecommentState}
            commentState={commentState}
            setcommentState={setcommentState}
          />
        </>
      ) : (
        ""
      )}
      <div style={{ paddingLeft: data.depth * 10, margin: "10px 0px" }}>
        {data.children.map((child, idx) => (
          <div key={idx}>
            {child.qnaType === "질문" ? (
              <QnaQuestion>
                <QnATemp
                  parentData={data}
                  data={child}
                  qnaType={qnaType}
                  setQnaType={setQnaType}
                  commentState={commentState}
                  setcommentState={setcommentState}
                ></QnATemp>
              </QnaQuestion>
            ) : (
              <QnaAnswer>
                <QnATemp
                  parentData={data}
                  data={child}
                  qnaType={qnaType}
                  setQnaType={setQnaType}
                  commentState={commentState}
                  setcommentState={setcommentState}
                ></QnATemp>
              </QnaAnswer>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
