import { Grid } from "@mui/material";
import MeritPctDetail from "../../../components/Admin/Statistics/MeritPctDetail";

interface Props {
  handleOpen: (open : boolean) => void;
}
function StatisticsMeritpct({handleOpen}: Props) {
  handleOpen(false);
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");
  const department = urlParams.get("department");
  const team = urlParams.get("team");
  return (
    <>
      {year ? (
        <Grid item xs={12}>
          <MeritPctDetail year={year} department={department} team={team}></MeritPctDetail>
        </Grid>
      ) : null}
    </>
  );
}

export default StatisticsMeritpct;
