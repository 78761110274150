export const defaultValue =
  // eslint-disable-next-line no-multi-str
  '<table id="namo-editor-table-class" name="namo-editor-table-class" cellspacing="0" cellpadding="5" border="1" style="width: 100%; font-size:10pt; border-width: 0px; border-color:#000000; border-collapse:collapse; border-style: solid; background-color: #FFFFFF "> \
<tbody> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">본 과제를 완료하고 나면 회사와 본인에게 어떤 변화가 기대되는가? (유형의 결과물, 무형의 가치, 과제 전후의 변화)</span></td> \
</tr> \
<td style="width: 100%; height: 80px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">현 문제사항이 어떤 것들이 있어 이 과제를 시작하게 되었는가? (현황 및 문제점)</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 80px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">과제 수행 시 예상되는 어려움은 무엇인가?</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 80px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">어떤 방법으로 문제를 해결하고 목표를 달성할 것인가? (전략 및 개선방안)</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 80px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">본 과제가 성공적이라는 것은 구체적으로 어떤 결과물을 통해 확인할 수 있는가? (목표 및 평가방법)</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 80px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
</tbody> \
</table>';
