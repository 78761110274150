import { AsmtDetail } from "../types/AsmtWork";
import { AttachFile } from "../types/type";
import HttpClient from "../http-client";
import client from "../ApiService";

const baseUri: string = "/file";
class _FileApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  /**
   * @description:
   * @returns:
   */
  async findFiles() {
    return await this.client.get<AttachFile[]>(baseUri + "/file").then((response) => {
      return response.data;
    });
  }

  /**
   * @description:
   * @returns:
   */
  async upload(formData: FormData) {
    return await this.client.post<AttachFile[]>(baseUri + "/upload/all", formData).then((response) => {
      return response.data;
    });
  }
  /**
   * @description:
   * @returns:
   */
  async download(fullTimerAsmtWork: AsmtDetail | undefined) {
    return await this.client.put<AsmtDetail>(baseUri + "/file/download", fullTimerAsmtWork).then((response) => {
      return response.data;
    });
  }
  /**
   * @description:
   * @returns:
   */
  async FileDownload(filename: string, filepath: string) {
    return await this.client
      .get(baseUri + `/download`, {
        params: { filename, filePath: filepath },
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  /**
   * @description:
   * @returns:
   */
  async DeleteExistFile(filename: string) {
    return await this.client.delete(baseUri + `/download/${filename}`).then((response) => {
      return response.data;
    });
  }
}

const FileApi = new _FileApi(client);
export default FileApi;
