import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  ChipIsResignation,
  StatisticsTitle,
  StyledBodyNoPadding,
  StyledBodyNoPaddingNumber,
  StyledHead1,
  StyledHeadTitle,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import StatisticsApi from "../../../system/api/StatisticsApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import {
  AggEmployeeDetail,
  AggTeamDetail,
} from "../../../system/types/Statistics";
import { AsmtWorkRoutes } from "../../../system/types/type";

interface PctDetailProps {
  year: string;
  department: string | null;
  team: string | null;
  participationType: string | null;
}

function PctDetail({
  year,
  department,
  team,
  participationType,
}: PctDetailProps) {
  const [data, setData] = useState<AggTeamDetail[]>([]);
  const [subData, setSubData] = useState<AggEmployeeDetail[]>();
  const ref = useRef<HTMLDivElement>(null);
  const Loading = useLoadingDispatch();

  useEffect(() => {
    Loading({ type: "LOADING" });
    department || team || participationType
      ? StatisticsApi.GetTeamDetailStatistics({
          year: year,
          department: department,
          team: team,
          aggregationStatus: "참여",
          participationType: participationType,
        })
          .then((res) => {
            setData(res.data);
            setSubData([]);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          })
      : StatisticsApi.GetTeamDetailStatistics({
          year: year,
          department: "",
          team: "",
          aggregationStatus: "참여",
          participationType: "전체",
        })
          .then((res) => {
            setData(res.data);
            setSubData([]);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
  }, [year, department, team, participationType, Loading]);

  const onHandleCell = (year: string, userId: number) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    StatisticsApi.GetEmployeeStatistics({ year: year, userId: userId })
      .then((res) => {
        setSubData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  };

  const onRowSelected = (id: number) => {
    window.open(`${window.location.origin}${AsmtWorkRoutes.root}/detail/${id}`);
  };

  const StyledClickBody = styled(TableCell)(({ theme }) => ({
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    padding: "0px",
    textDecoration: "underline",
  }));

  const subTitle = {
    whiteSpace: "nowrap" as const,
    overflowX: "auto" as const,
    maxWidth: "300px",
  };

  const commonStyle = { whiteSpace: "nowrap" as const };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <StatisticsTitle>
          {team ? (
            <>{team} 과제</>
          ) : (
            <>{department ? <>{department} 과제 </> : "전사 과제 "}</>
          )}
          {participationType === "null" || participationType === "전체" ? (
            " 참여 인원 현황"
          ) : (
            <>{participationType} 인원 현황</>
          )}
        </StatisticsTitle>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center">파트</StyledHead1>
                <StyledHead1 align="center">팀</StyledHead1>
                <StyledHead1 align="center">이름</StyledHead1>
                <StyledHead1 align="center">직급</StyledHead1>
                <StyledHead1 align="center">총 참여 과제(개)</StyledHead1>
                <StyledHead1 align="center">PM 과제(개)</StyledHead1>
                <StyledHead1 align="center">멤버 과제(개)</StyledHead1>
                <StyledHead1 align="center">성과급(원)</StyledHead1>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <StyledBodyNoPadding align="center">
                        {item.department}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding align="center">
                        {item.team}
                      </StyledBodyNoPadding>
                      <StyledClickBody
                        align="center"
                        onClick={() => onHandleCell(year, item.userId)}
                      >
                        {item.name}
                        {item.isResignation && (
                          <ChipIsResignation>퇴사</ChipIsResignation>
                        )}
                      </StyledClickBody>
                      <StyledBodyNoPadding align="center">
                        {item.jobPosition}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPaddingNumber align="right">
                        {item.totalWorks}
                      </StyledBodyNoPaddingNumber>
                      <StyledBodyNoPaddingNumber align="right">
                        {item.projectManagers}
                      </StyledBodyNoPaddingNumber>
                      <StyledBodyNoPaddingNumber align="right">
                        {item.members}
                      </StyledBodyNoPaddingNumber>
                      <StyledBodyNoPaddingNumber align="right">
                        {item.totalMeritPay
                          .toLocaleString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </StyledBodyNoPaddingNumber>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {subData === undefined || subData.length < 1 ? null : (
        <>
          {subData && (
            <Grid item xs={12} ref={ref}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHead1 align="center">파트</StyledHead1>
                      <StyledHead1 align="center">팀</StyledHead1>
                      <StyledHead1 align="center">이름</StyledHead1>
                      <StyledHead1 align="center">직급</StyledHead1>
                      <StyledHeadTitle align="center">과제명</StyledHeadTitle>
                      <StyledHead1 align="center">PM/참여자</StyledHead1>
                      <StyledHead1 align="center">총 성과급(원)</StyledHead1>
                      <StyledHead1 style={{ width: "100px" }} align="center">
                        비율(%)
                      </StyledHead1>
                      <StyledHead1 align="center">실 성과급(원)</StyledHead1>
                      <StyledHead1 align="center">지급시기(년)</StyledHead1>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subData.map((sub, idx) => {
                      return (
                        <TableRow
                          key={idx}
                          onClick={() => onRowSelected(sub.workId)}
                        >
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.department}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.team}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.name}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.jobPosition}
                          </StyledBodyNoPadding>
                          <StyledClickBody style={subTitle}>
                            {sub.workTitle}
                          </StyledClickBody>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.projectManager ? "PM" : "멤버"}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="right"
                          >
                            {sub.meritPay
                              .toLocaleString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.contributionRate}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="right"
                          >
                            {sub.actualMeritPay
                              .toLocaleString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </StyledBodyNoPadding>
                          <StyledBodyNoPadding
                            style={commonStyle}
                            align="center"
                          >
                            {sub.payPeriod}
                          </StyledBodyNoPadding>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default PctDetail;
