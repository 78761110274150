export const defaultInternValue =
  // eslint-disable-next-line no-multi-str
  '<table id="namo-editor-intern-table-class" name="namo-editor-intern-table-class" cellspacing="0" cellpadding="5" border="1" style="width: 100%; font-size:10pt; border-width: 0px; border-color:#000000; border-collapse:collapse; border-style: solid; background-color: #FFFFFF "> \
<tbody> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">과제의 배경</span></td> \
</tr> \
<td style="width: 100%; height: 100px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">과제 수행 시 예상되는 어려움은 무엇인가?</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 100px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">어떤 방법으로 문제를 해결하고 목표를 달성할 것인가?</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 100px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">결과물이 어떨 것으로 예상되는가?</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 100px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
</tbody> \
</table>';
