import client from "../ApiService";
import HttpClient from "../http-client";
import { AdminUser, Status, UserRole } from "../types/type";

const baseUri: string = "/graph/roles";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AdminApi extends _BasicApi {
  GetRolesList() {
    return this.client.get(`${baseUri}/list`);
  }
  GetAdminList() {
    return this.client.get(`${baseUri}/user`);
  }
  CreateAdmin(data: UserRole) {
    return this.client.post(`${baseUri}`, data);
  }
  // UpdateAdmin() {
  //   return this.client.put(`${baseUri}/update`, meetingNote);
  // }
  DeleteAdmin(data: AdminUser) {
    return this.client.delete(`${baseUri}`, { data: data });
  }
  UpdateAdminStatus(data: Status) {
    return this.client.put("/admin/asmt/work/status", data);
  }
}

const AdminApi = new _AdminApi(client);
export default AdminApi;
