import HttpClient from "../http-client";
import client from "../ApiService";
import { DevelopNoteDetail } from "../types/DevelopNote";

const baseUri: string = "/article/develop";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _DevelopNoteApi extends _BasicApi {
  getDevelopNoteList(parentId: number) {
    return this.client.get(`${baseUri}/all/${parentId}`);
  }
  createDevelopNote(developNote: DevelopNoteDetail) {
    return this.client.post(`${baseUri}/create`, developNote);
  }
  getDevelopNoteDetail(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  updateDevelopNote(developNote: DevelopNoteDetail) {
    return this.client.put(`${baseUri}/update`, developNote);
  }
  deleteDevelopNote(id: number) {
    return this.client.delete(`${baseUri}/delete/${id}`);
  }
}

const DevelopNoteApi = new _DevelopNoteApi(client);
export default DevelopNoteApi;
