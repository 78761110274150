import { createTheme, TableCell, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: "3.8rem",
    },
    h2: {
      fontSize: "3.3rem",
    },
    h3: {
      fontSize: "2.5rem",
    },
    h4: {
      fontSize: "2.0rem",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1rem",
    },
  },
  palette: {
    primary: {
      light: "#666f73",
      main: "#971B2F",
      dark: "#2c3438",
      contrastText: "#fff",
    },
    secondary: {
      light: "#A2AAAD",
      main: "#5B6770",
      dark: "#601317",
      contrastText: "#fff",
    },
  },
});

export const ContentWrapper = styled("div")(({ theme }) => ({
  minWidth: "1200px",
  marginTop: "21px",
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: "200ms",
  }),
  marginLeft: 240,
}));
export const ContentShift = styled("div")(({ theme }) => ({
  minWidth: "1200px",
  marginTop: "10px",
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: "200ms",
  }),
  marginLeft: 0,
}));
export const ContentBody = styled("div")(({ theme }) => ({
  width: "1400px",
  "@media (max-width: 1680px)": {
    width: "1400px",
  },
  "@media (max-width: 1440px)": {
    width: "1200px",
  },
  "@media (max-width: 1380px)": {
    width: "1200px",
  },
  "@media (max-width: 1279px)": {
    width: "1200px",
  },
}));

// header
export const HeaderStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));
export const MenuButton = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
export const Title = styled("div")(() => ({
  flexGrow: 1,
  marginLeft: "10px",
}));
export const NavProfile = styled("div")(() => ({
  marginRight: "10px",
}));

export const FooterStyle = styled("div")(({ theme }) => ({
  height: "60px",
  width: "100%",
  padding: "10px",
  position: "absolute",
  marginBottom: theme.spacing(1),
  textAlign: "center",
  zIndex: -1,
}));

export const BodyWrap = styled("div")(({ theme }) => ({
  minHeight: "100%",
  position: "relative",
  marginTop: "60px",
  padding: "2px",
  marginLeft: "60px",
}));

export const BasicTable = styled("table")(({ theme }) => ({
  marginTop: "10px",
  width: "100%",
  borderTop: "1px solid #e0e0e0",
}));

export const BasicTr = styled("tr")(({ theme }) => ({
  borderTop: "1px solid #e0e0e0",
  borderBottom: "1px solid #e0e0e0",
  "&>th, &>td": {
    padding: "15px",
  },
  "&>th": {
    width: "130px",
  },
}));

export const AttachmentFileLayout = styled("div")(({ theme }) => ({
  padding: "20px 0px",
  display: "flex",
  borderTop: "1px solid #e0e0e0",
  borderBottom: "1px solid #e0e0e0",
  "&>th, &>td": {
    padding: "15px",
  },
  "&>th": {
    width: "130px",
  },
}));

export const AttachmentFileLayoutTitle = styled("div")(({ theme }) => ({
  paddingRight: "15px",
}));

export const BasicTh = styled("th")(({ theme }) => ({
  borderLeft: "1px solid #DCDFE3",
}));

export const CommentTab = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
  height: "20px",
}));

export const DatePickerCustom = styled("div")(({ theme }) => ({
  "& .e-input-group.e-control-wrapper.e-custom.e-datetime-wrapper": {
    backgroundColor: "blue",
    border: "2px solid",
    color: "purple",
    borderColor: "blue",
  },
}));

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#e3165b",
    },
  },
});

export const CustomButton = styled(ButtonComponent)({
  backgroundColor: "#FFFFFF",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "5px",
  fontSize: "14px",
});

export const CustomButtonRed = styled(ButtonComponent)({
  backgroundColor: "#971B2F",
  color: "#FFFFFF",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "5px",
  fontSize: "14px",
});

export const CustomButtonGrey = styled(ButtonComponent)({
  backgroundColor: "#5B6770",
  color: "#FFFFFF",
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "5px",
  fontSize: "14px",
});

export const DailyWriteTextarea = styled("textarea")({
  marginBottom: "20px",
  marginLeft: "20px",
  width: "100%",
  resize: "none",
});

export const StyledListComponent = styled(DropDownListComponent)(
  ({ theme }) => ({
    height: "29px",
  })
);

export const StyledHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F0F2F5",
  border: "1px solid #e0e0e0",
  width: "150px",
  padding: "4px",
  height: "52px",
  // minWidth: "180px",
}));

export const StyledHeadTitle = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F0F2F5",
  border: "1px solid #e0e0e0",
  minWidth: "300px",
  padding: "4px",
  // minWidth: "180px",
}));

export const StyledHead1 = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F0F2F5",
  border: "1px solid #e0e0e0",
  width: "150px",
  padding: "4px",
  whiteSpace: "nowrap" as const,
}));

export const StyledHead2 = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F7F8FA",
  border: "1px solid #e0e0e0",
  width: "150px",
  padding: "4px",
  // minWidth: "180px",
}));

export const StyledBodyName = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  width: "300px",
  padding: "4px",
  // minWidth: "180px",
}));

export const StyledBody = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  height: "60px",
  // minWidth: "120px",
}));
export const StyledBody2 = styled(TableCell)(({ theme }) => ({
  borderLeft: "1px solid #e0e0e0",
  borderRight: "1px solid #e0e0e0",
  borderBottom: "1px solid #e0e0e0",
  height: "52px",
  // minWidth: "120px",
}));

export const StyledBodyPa5 = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  padding: "4px",
  // minWidth: "120px",
}));

export const StyledBodyNoPadding = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  padding: "4px",
}));

export const StyledBodyNoPaddingPart = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  padding: "4px",
  cursor: "pointer",
  width: "600px",
}));

export const StyledBodyNoPaddingNumber = styled(TableCell)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  padding: "4px",
  paddingRight: "30px",
}));

export const StatisticsTitle = styled("div")(({ theme }) => ({
  border: "1px solid black",
  padding: "4px",
  display: "flex",
  justifyContent: "center",
}));

export const ValidationText = styled("div")(({ theme }) => ({
  color: "#CC1F3B",
  fontSize: "0.8rem",
}));

export const QnaQuestion = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #e0e0e0",
  margin: "10px 0px",
  padding: "10px",
}));

export const QnaAnswer = styled("div")(({ theme }) => ({
  backgroundColor: "#0000000a",
  margin: "10px 0px",
  padding: "10px",
}));

export const StatusAgg = styled("span")(({ theme }) => ({
  cursor: "pointer",
  fontWeight: "600",
  fontSize: "0.875rem",
}));

export const DropDown = styled("div")(({ theme }) => ({
  "& .e-ddl.e-input-group.e-control-wrapper .e-input": {
    fontSize: "0.825rem",
  },
  "& span input": {
    padding: "4px 7px 4px !important",
  },
  "& span span": {
    margin: "4px 7px 4px 0 !important",
  },
}));

export const TextNoMargin = styled("div")(({ theme }) => ({
  "& span": {
    marginBottom: "0px !important",
  },
}));

export const ReportLayout = styled("div")(({ theme }) => ({
  margin: "8px 0px 10px 0px",
}));

export const DataGridContent = styled("div")(({ theme }) => ({
  fontSize: "14px",
  cursor: "pointer",
}));

export const ChipIsResignation = styled("span")(({ theme }) => ({
  marginLeft: "5px",
  padding: "0px 5px",
  fontSize: "12px",
  color: "#CC1F3B",
  border: "1px solid #CC1F3B",
  borderRadius: "50px",
  textAlign: "center",
}));
