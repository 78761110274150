import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  StatisticsTitle,
  StyledBodyNoPadding,
  StyledBodyNoPaddingNumber,
  StyledHead1,
  StyledHeadTitle,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { CommonWorkApi } from "../../../system/api/CommonApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AsmtList } from "../../../system/types/AsmtWork";
import { InitAsmtList, InitAsmtSearch } from "../../../system/types/initObject";
import { AsmtWorkRoutes } from "../../../system/types/type";

interface ExecutivesDetailProps {
  searchWord: string;
  year: string;
}

function ExecutivesDetail({ searchWord, year }: ExecutivesDetailProps) {
  const [data, setData] = useState<AsmtList>(InitAsmtList);
  const Loading = useLoadingDispatch();

  useEffect(() => {
    Loading({ type: "LOADING" });
    CommonWorkApi.GetAsmtWorkList("full-timer", "all", {
      ...InitAsmtSearch,
      year: year,
      searchType: "참여자",
      searchWord: searchWord,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, searchWord]);

  const onRowSelected = (id: number) => {
    window.open(`${window.location.origin}${AsmtWorkRoutes.root}/detail/${id}`);
  };

  const subTitle = {
    whiteSpace: "nowrap" as const,
    overflowX: "auto" as const,
    maxWidth: "500px",
  };

  const commonStyle = { whiteSpace: "nowrap" as const };

  const StyledClickBody = styled(TableCell)(({ theme }) => ({
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    padding: "0px",
    textDecoration: "underline",
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <StatisticsTitle>세부 내용</StatisticsTitle>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHead1 align="center">분류</StyledHead1>
                <StyledHead1 align="center">파트</StyledHead1>
                <StyledHead1 align="center">PM</StyledHead1>
                <StyledHeadTitle align="center">과제명</StyledHeadTitle>
                <StyledHead1 style={{ width: "180px" }} align="center">
                  참여자수
                </StyledHead1>
                <StyledHead1 align="center">진행상태</StyledHead1>
                <StyledHead1 align="center">성과급(원)</StyledHead1>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.works.map((work, idx) => {
                  return (
                    <TableRow
                      hover
                      key={idx}
                      onClick={() => onRowSelected(work.id)}
                    >
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {work.classification}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {work.participants
                          .filter((x) => x.projectManager)
                          .map((x) => x.department)
                          .join(",")}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {work.participants
                          .filter((x) => x.projectManager)
                          .map((x) => x.name)
                          .join(",")}
                      </StyledBodyNoPadding>
                      <StyledClickBody style={subTitle}>
                        {work.title}
                      </StyledClickBody>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {work.participantCnt}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPadding style={commonStyle} align="center">
                        {work.status}
                      </StyledBodyNoPadding>
                      <StyledBodyNoPaddingNumber
                        style={commonStyle}
                        align="right"
                      >
                        {work.meritPay
                          .toLocaleString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </StyledBodyNoPaddingNumber>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default ExecutivesDetail;
