import client from "../ApiService";
import HttpClient from "../http-client";
import { Notice } from "../types/type";

const baseUri: string = "/article/notice";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _NoticeApi extends _BasicApi {
  getNoticeAll() {
    return this.client.get(`${baseUri}/all`);
  }
  getNoticeDetail(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  createNotice(notice: Notice) {
    return this.client.post(`${baseUri}/create`, notice);
  }
  updateNotice(notice: Notice) {
    return this.client.put(`${baseUri}/update`, notice);
  }
  deleteNotice(id: number) {
    return this.client.delete(`${baseUri}/delete/${id}`);
  }
}

export const NoticeApi = new _NoticeApi(client);
