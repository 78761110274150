import { UserInfo } from "./AsmtWork";

export enum CommonRoutes {
  root = "/",
  logout = "/logout",
  login = "/login",
}

export enum NoticeRoutes {
  root = "/notice",
  edit = "/notice/edit/:id?",
  detail = "/notice/detail/:id",
}

export enum AsmtWorkRoutes {
  root = "/asmt/work", //과제업무 메인
  edit = "/asmt/work/edit/:id?",
  detail = "/asmt/work/detail/:id",
  owned = "/asmt/work/owned",
  members = "/asmt/work/members",
  internList = "/asmt/work/interns",
  intern = "/asmt/work/intern",
  internEdit = "/asmt/work/intern/edit/:id?",
  internDetail = "/asmt/work/intern/detail/:id",
}

export enum DailyWorkRoutes {
  root = "/daily/work", //일일 업무 메인
  detail = "/daily/work/detail/:id",
  edit = "/daily/work/edit/:id?",
}

export enum MeetingNoteRoutes {
  root = "/meetingNote",
  // write = "/meetingNote/write",
  detail = "/meetingNote/detail/:id",
  edit = "/meetingNote/edit/:id?",
}

export enum DevelopNoteRoutes {
  root = "/developNote",
  write = "/developNote/write",
  detail = "/developNote/detail/:id",
  edit = "/developNote/edit/:id?",
}

export enum ReportRoutes {
  root = "/report",
  write = "/report/write",
  detail = "/report/detail/:id",
  edit = "/report/edit/:id?",
}


export enum GuideRoutes {
  root = "/manual",
  detail = "/manual/detail/:id",
  edit = "/manual/edit/:id?",
  qnaRoot = "/qna",
  qnaDetail = "/qna/detail/:id",
  qnaEdit = "/qna/edit/:id?",
  guide = "/guide"
}

export enum AdminRoutes {
  asmt = "/admin/asmt",
  asmtDetail = "/admin/asmt/:id",
  asmtEdit = "/admin/asmt/edit/:id",
  intern = "/admin/intern",
  internDetail = "/admin/intern/:id",
  internEdit = "/admin/intern/edit/:id",
  statistics = "/admin/statistics",
  role = "/admin/role",
  statisticsPct = "/admin/pct/popup",
  statisticsNonpct = "/admin/nonpct/popup",
  statisticsMeritpct = "/admin/statisticsMeritpct/popup",
  statisticsExe = "/admin/exec/popup",
}

export enum ApproveRoutes {
  detail = "/hr/schedule/confirm/:id",
}

export enum QnaRoutes {
  root = "/qna",
}

export type pageType = "View" | "Update" | "Add" | "Admin";
export type WorkStatus = "임시저장" | "등록대기" | "진행" | "완료" | "중단";

export interface GridData {
  result: any[];
  count: number;
}

export interface Notice {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  updated: string;
  writer: WorkUser;
  mainNoticeStartDate: string;
  mainNoticeEndDate: string;
  mainNotice: boolean;
  attachmentFiles: AttachFile[];
  secret: boolean;
  setInfo: boolean;
}

export interface WorkUser {
  id: number;
  name: string;
  email: string;
  jobTitle: string;
  jobPosition: string;
  group: string;
  team: string;
  department: string;
  contributionRate?: number;
  projectManager?: boolean;
  mentor?: boolean;
}

export interface Comment {
  type: string;
  targetId: number;
  depth: number;
  parentId: any;
  content: string;
  dueDate: string;
  confirm: boolean;
  examine: boolean;
  id: number;
  sendMail: boolean;
}

export interface CommentConfirm {
  page: number;
  size: number;
  sort: [];
}

export interface CommentInfo extends Comment {
  createdAt: string;
  updatedAt: string;
  user: UserInfo;

  children: [];
}

export interface NoteList {
  comments: NoteListItem[];
  totalPages: number;
  totalElements: number;
}

export interface NoteListItem {
  id: number;
  dueDate: string;
  confirm: boolean;
  examine: boolean;
  workId: number;
  title: string;
  classification: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface AttachFile {
  filename: string;
  filePath: string;
}

export interface Ilocation {
  parentId: number;
  parentTitle: string;
  workType: string;
}

export interface IlocationIntern {
  parentId: number;
  parentTitle: string;
  workType: string;
  participant: WorkUser[];
}

export interface AdminUser {
  appRoleId: string;
  description: string;
  displayName: string;
  enabled: boolean;
  principalId: string;
  principalUser: WorkUser;
  roleAssignmentId: string;
  value: string;
}

export interface Status {
  workId: number;
  status: string;
}

export interface Roles {
  oDataType?: string;
  allowedMemberTypes: string[];
  description: string;
  displayName: string;
  id: string;
  isEnabled: boolean;
  origin: string;
  value: string;
}

export interface UserRole {
  role?: Roles;
  user?: WorkUser;
}

export interface Qna {
  articles: QnaContent[];
  totalPages: number;
  totalElements: number;
}

export interface QnaContent {
  id: number;
  title: string;
  depth?: any;
  qnaType: string;
  qnaCls: string;
  writer: WorkUser;
  createdAt: string;
  content: string;
  parentId?: any;
  attachmentFiles: AttachFile[];
  children: QnaContent[];
  secret: boolean;
  setInfo: boolean;
}
