import HttpClient from "../http-client";
import client from "../ApiService";
import { scheduleConfirm } from "../types/Hr";


const baseUri: string = "/hr/schedule";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _HrApi extends _BasicApi { 
    createApprove(confirmData: scheduleConfirm) {
        return this.client.post(`${baseUri}/confirm`, confirmData)
     }
}

const HrApi = new _HrApi(client);
export default HrApi;