import { styled, Typography } from "@mui/material";

const SubTitleBar = styled("div")(({ theme }) => ({
  backgroundColor: "#F0F2F5",
  padding: "10px",
  fontWeight: 500,
}));

interface TitleDivProps {
  title: string;
}

function SubTitleDiv({ title }: TitleDivProps) {
  return (
    <SubTitleBar>
      <Typography>과제명 : {title}</Typography>
    </SubTitleBar>
  );
}

export default SubTitleDiv;
