import client from "../ApiService";
import HttpClient from "../http-client";
import { Comment } from "../types/type";

const baseUri: string = "/comment";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _CommentApi extends _BasicApi {
  CreateComment(comment: Comment) {
    return this.client.post(`${baseUri}/create`, comment);
  }
  DeleteComment(id: number) {
    return this.client.delete(`${baseUri}/delete/${id}`);
  }
  UpdateComment(comment: Comment) {
    return this.client.put(`${baseUri}/update`, comment);
  }
  ConfirmComment(id: number) {
    return this.client.put(`${baseUri}/confirm`, { commentId: id });
  }
  ConfirmCommentList(isWork: boolean, page: number, size: number) {
    return this.client.get(`${baseUri}/confirm`, {
      params: { isWork: isWork, page: page, size: size },
    });
  }
}

export const CommentApi = new _CommentApi(client);
