import client from "../ApiService";
import HttpClient from "../http-client";
import { dailyWorkEdit, dailyWorkWrite } from "../types/dailyWork";

const baseUri: string = "/article/daily-work";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _DailyApi extends _BasicApi {
  getDailyWorkAll(workDate: string) {
    return this.client.get(`${baseUri}/all`, { params: { workDate: workDate } });
  }
  getDailyWorkDetail(id: number) {
    return this.client.get(`${baseUri}/detail/${id}`);
  }
  getDailyExist(workDate: string) {
    return this.client.get(`${baseUri}/exist`, { params: { workDate: workDate } });
  }
  createDailyWork(DailyWork: dailyWorkWrite) {
    return this.client.post(`${baseUri}/create`, DailyWork);
  }
  updateDailyWork(DailyWork: dailyWorkEdit) {
    return this.client.put(`${baseUri}/update`, DailyWork);
  }
  deleteDailyWork(workDate: string) {
    return this.client.delete(`${baseUri}/delete`, { params: { workDate: workDate } });
  }
}

export const DailyApi = new _DailyApi(client);
