import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import AsmtTable from "../Asmt/Main/AsmtTable";
import AsmtStatus from "./AsmtStatus";
import PartStatus from "./PartStatus";
import { useRecoilState } from "recoil";
import { yearState } from "../../system/atoms";

function MainPage() {
  const [year, setYear] = useRecoilState(yearState);
  const [state, setState] = useState("");
  const changeYear = (args: any) => {
    setYear(args.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold">
          과제 업무란?
        </Typography>
        <Typography variant="body2">
          1. 스스로 할 일에 대한 정의를 내리고, 일의 마무리(목적)를 분명히 하여
          일을 완성해 나가는 업무
        </Typography>
        <Typography variant="body2">
          2. 일을 제대로 하는 방법을 배우고, 한 분야의 전문가로 성장하기 위한
          업무
        </Typography>
        <Typography variant="body2">
          3. 성공 경험을 토대로, 삶의 주제를 찾게 해주는 업무
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AsmtStatus
          year={year}
          onChangeYear={changeYear}
          setState={setState}
        ></AsmtStatus>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <PartStatus year={year}></PartStatus>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <AsmtTable
          main={true}
          state={state}
          setState={setState}
          setYear={setYear}
          year={year}
        ></AsmtTable>
      </Grid>
    </Grid>
  );
}

export default MainPage;
