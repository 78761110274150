import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import AsmtTable from "../../components/Asmt/Main/AsmtTable";
import { useState } from "react";

function AsmtMain(): ReactJSXElement {
  const [state, setState] = useState("");
  const [year, setYear] = useState<string>("2024");
  return (
    <AsmtTable
      main={false}
      state={state}
      setState={setState}
      year={year}
      setYear={setYear}
    ></AsmtTable>
  );
}

export default AsmtMain;
