import { CircularProgress, Modal } from "@mui/material";
import { Dispatch, createContext, useContext, useReducer } from "react";

type LoadingState = {
  loading: boolean;
  message: string | null;
};

type Action = { type: "LOADING_MESSAGE"; message: string } | { type: "COMPLETE" } | { type: "LOADING" };
type LoadingDispatch = Dispatch<Action>;

const LoadingStateContext = createContext<LoadingState | undefined>(undefined);
const LoadingDispatchContext = createContext<LoadingDispatch | undefined>(undefined);

function LoadingReducer(state: LoadingState, action: Action): LoadingState {
  switch (action.type) {
    case "LOADING_MESSAGE":
      return {
        loading: true,
        message: action.message,
      };
    case "LOADING":
      return {
        loading: true,
        message: "Loading...",
      };
    case "COMPLETE":
      return {
        loading: false,
        message: null,
      };
  }
}

export function LoadingContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(LoadingReducer, {
    loading: false,
    message: null,
  });

  return (
    <LoadingDispatchContext.Provider value={dispatch}>
      <LoadingStateContext.Provider value={state}>
        {children}
        <Modal
          open={state.loading}
          style={{
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
        >
          <div className="overlay-content">
            <div className="wrapper">
              <CircularProgress color="secondary" size={50} />
              <span className="message">{state.message}</span>
            </div>
          </div>
        </Modal>
      </LoadingStateContext.Provider>
    </LoadingDispatchContext.Provider>
  );
}

//custom hook
export function useLoadingState() {
  const state = useContext(LoadingStateContext);
  if (!state) throw new Error("LoadingStateContext not found");
  return state;
}

export function useLoadingDispatch() {
  const dispatch = useContext(LoadingDispatchContext);
  if (!dispatch) throw new Error("LoadingDispatchContext not found");
  return dispatch;
}
