import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  CustomButton,
  PRIMARY,
  QnaAnswer,
  QnaQuestion,
  StyledBody,
  StyledBody2,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { QnaApi } from "../../../system/api/QnaApi";
import FileApi from "../../../system/api/fileApi";
import { useUserState } from "../../../system/context/UserContext";
import { InitQnaContent } from "../../../system/types/initObject";
import { QnaContent, QnaRoutes } from "../../../system/types/type";
import HtmlContent from "../../Common/HtmlContent";
import TitleDiv from "../../Common/TitleDiv";
import QnAComment from "./QnAComment";
import { QnATemp } from "./QnaTemp";

interface Params {
  id: string;
}

function QnAView() {
  const { id } = useParams<Params>();
  const qnaId = parseInt(id);
  const history = useHistory();
  const [data, setData] = useState<QnaContent>(InitQnaContent);
  const user = useUserState();
  const [qnaType, setQnaType] = useState("");
  const [, setRecommentState] = useState("");
  const [commentState, setcommentState] = useState("view");

  useEffect(() => {
    if (qnaId) {
      if (data.secret) {
        if (data.writer.name === user.name || user.roles.includes("Admin")) {
        } else {
          alert("해당 글은 작성자와 관리자만 확인 가능합니다.");
          history.push(QnaRoutes.root);
        }
      }
      QnaApi.getQnaDetail(qnaId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      alert("잘못된 접근입니다.");
      history.push(QnaRoutes.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qnaId, commentState]);

  const downloadFile = (filename: string, filepath: string) => {
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."));
  };

  const onDeleteQna = () => {
    if (window.confirm("문의하기를 정말 삭제하시겠습니까?")) {
      QnaApi.deleteQna(data.id)
        .then(() => {
          history.push(QnaRoutes.root);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
  };

  const onUpdateQna = () => {
    history.push(`${QnaRoutes.root}/edit/${data.id}`);
  };

  // css
  const leftBtn = { marginLeft: "10px" };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="문의하기(QnA) 상세"></TitleDiv>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        {data.writer.name === user.name ? (
          <>
            <CustomButton
              style={{ color: PRIMARY }}
              iconCss="e-icons e-delete-1"
              onClick={onDeleteQna}
            >
              삭제
            </CustomButton>

            <CustomButton
              style={leftBtn}
              iconCss="e-icons e-edit"
              onClick={onUpdateQna}
            >
              수정
            </CustomButton>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <StyledBody
                  style={{
                    backgroundColor: "#F0F2F5",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={9}>
                    <Typography variant="body1" color={"grey"}>
                      {data.qnaCls}
                    </Typography>
                    <Typography variant="body1">{data.title}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography
                      variant="body1"
                      sx={{ textAlign: "right", marginRight: "10px" }}
                    >
                      {data.writer.department} {data.writer.name} /{" "}
                      {data.createdAt}
                    </Typography>
                  </Grid>
                </StyledBody>
              </TableRow>
              <TableRow>
                <StyledBody2>
                  <HtmlContent
                    style={{ minHeight: "300px" }}
                    data={
                      data.content ? data.content : "작성된 내용이 없습니다."
                    }
                  ></HtmlContent>
                </StyledBody2>
              </TableRow>
              {data.attachmentFiles.length > 0 ? (
                <TableRow>
                  <StyledBody>
                    <Typography variant="body1" color={"grey"}>
                      첨부파일
                    </Typography>
                    {data.attachmentFiles.map((file, idx) => {
                      return (
                        <div key={idx} style={{ cursor: "pointer" }}>
                          <Typography
                            variant="body1"
                            onClick={() =>
                              downloadFile(file.filename, file.filePath)
                            }
                          >
                            {file.filename}
                          </Typography>
                        </div>
                      );
                    })}
                  </StyledBody>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer style={{ marginTop: "10px" }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: "1px solid #e0e0e0" }}>
                  <QnAComment
                    parentData={data}
                    commentId={0}
                    qnaType="QnA"
                    setQnaType={setQnaType}
                    setRecommentState={setRecommentState}
                    commentState={commentState}
                    setcommentState={setcommentState}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        {data.children.length > 0 ? (
          <>
            {data.children.map((child: QnaContent, idx) => (
              <div key={"comment" + idx}>
                {child.qnaType === "질문" ? (
                  <QnaQuestion>
                    <QnATemp
                      parentData={data}
                      data={child}
                      qnaType={qnaType}
                      setQnaType={setQnaType}
                      commentState={commentState}
                      setcommentState={setcommentState}
                    />
                  </QnaQuestion>
                ) : (
                  <QnaAnswer>
                    <QnATemp
                      parentData={data}
                      data={child}
                      qnaType={qnaType}
                      setQnaType={setQnaType}
                      commentState={commentState}
                      setcommentState={setcommentState}
                    />
                  </QnaAnswer>
                )}
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}

export default QnAView;
