import { Grid } from "@mui/material";
import TitleDiv from "../../Common/TitleDiv";
import NoteAdminTable from "./NoteAdminTable";

function AsmtAdminMain() {

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title="Note 관리"></TitleDiv>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <NoteAdminTable></NoteAdminTable>
      </Grid>
    </Grid>
  );
}

export default AsmtAdminMain;
