import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  CustomButton,
  PRIMARY,
  SECONDARY,
  StyledBody,
  StyledHead,
} from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { InternApi } from "../../../system/api/InternApi";
import FileApi from "../../../system/api/fileApi";
import { useUserState } from "../../../system/context/UserContext";
import { InternWorkDetail } from "../../../system/types/InternWork";
import {
  AdminRoutes,
  AsmtWorkRoutes,
  pageType,
} from "../../../system/types/type";
import HtmlContent from "../../Common/HtmlContent";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface InternCompleteProps {
  data: InternWorkDetail;
  type?: pageType;
}

function InternComplete({ data, type }: InternCompleteProps) {
  const history = useHistory();
  const user = useUserState();
  const Loading = useLoadingDispatch();

  const onUpdatePage = () => {
    history.push({
      pathname: `${AsmtWorkRoutes.intern}/edit/${data.id}`,
    });
  };

  const onUpdateByAdmin = () => {
    history.push(`${AdminRoutes.intern}/edit/${data.id}`);
  };

  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const onDeleteIntern = () => {
    if (window.confirm("과제 기획서를 정말 삭제하시겠습니까?")) {
      Loading({ type: "LOADING" });
      InternApi.deleteInternWork(data.id)
        .then(() => {
          history.push(AsmtWorkRoutes.intern);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const onPrintHandler = () => {
    const html = document.querySelector("html");
    const printContents = document.getElementById("download_area")?.innerHTML;
    const printDiv = document.createElement("DIV");

    if (html && printContents) {
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";
    }
  };

  const chip = {
    color: "white",
    margin: "2px",
  };

  const leftBtn = { marginLeft: "10px" };

  if (data) {
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            {user.roles.includes("Admin") ? (
              <CustomButton iconCss="e-icons e-edit" onClick={onUpdateByAdmin}>
                관리자 수정
              </CustomButton>
            ) : (
              <></>
            )}
            {data.participants.findIndex((x) => x.name === user.name) !== -1 &&
            type === "View" ? (
              <>
                {data.status === "임시저장" && (
                  <CustomButton
                    style={{ ...leftBtn, color: PRIMARY }}
                    iconCss="e-icons e-delete-1"
                    onClick={onDeleteIntern}
                  >
                    삭제
                  </CustomButton>
                )}
                {data.status === "완료" || data.status === "중단" ? null : (
                  <CustomButton
                    style={leftBtn}
                    iconCss="e-icons e-edit"
                    onClick={onUpdatePage}
                  >
                    수정
                  </CustomButton>
                )}
              </>
            ) : (
              <></>
            )}
            {user.roles.includes("Admin") ||
            data.participants.findIndex((x) => x.name === user.name) !== -1 ? (
              <CustomButton
                style={leftBtn}
                iconCss="e-icons e-download"
                onClick={onPrintHandler}
              >
                다운로드
              </CustomButton>
            ) : null}
          </Grid>
          <Grid item xs={12} id="download_area">
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <StyledHead align="center">과제명</StyledHead>
                    <StyledBody colSpan={5}>{data.title}</StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead align="center">참여자</StyledHead>
                    <StyledBody colSpan={3} style={{ padding: "5px" }}>
                      {data.participants.map((user, idx) => {
                        return (
                          <Chip
                            key={idx}
                            style={{
                              ...chip,
                              backgroundColor: user.mentor
                                ? "#1b4c97"
                                : SECONDARY,
                            }}
                            label={user.mentor ? "MT " + user.name : user.name}
                          />
                        );
                      })}{" "}
                    </StyledBody>
                    <StyledHead align="center">참여자 수</StyledHead>
                    <StyledBody>{data.participantCnt}</StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead align="center">작성일</StyledHead>
                    <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                      {data.startDate === ""
                        ? "-"
                        : new Date(data.startDate).toLocaleDateString()}
                    </StyledBody>
                    <StyledHead align="center">진행상태</StyledHead>
                    <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                      {data.status}{" "}
                      {data.subStatus ? <>-{data.subStatus}</> : null}
                    </StyledBody>
                    {data.status === "완료" && (
                      <>
                        <StyledHead align="center">종료일</StyledHead>
                        <StyledBody colSpan={data.status === "완료" ? 1 : 2}>
                          {data.endDate
                            ? new Date(data.endDate).toLocaleDateString()
                            : "-"}
                        </StyledBody>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    <StyledBody colSpan={6}>
                      <HtmlContent
                        style={{ minHeight: "300px" }}
                        data={
                          data.content
                            ? data.content
                            : "작성된 내용이 없습니다."
                        }
                      ></HtmlContent>
                    </StyledBody>
                  </TableRow>
                  <TableRow>
                    <StyledHead
                      align="center"
                      style={{ padding: "8px", height: "52px" }}
                    >
                      첨부파일
                    </StyledHead>
                    <StyledBody colSpan={5}>
                      {data.attachmentFiles
                        ? data.attachmentFiles.map((file, idx) => {
                            return (
                              <div key={idx} style={{ cursor: "pointer" }}>
                                <Typography
                                  variant="body2"
                                  onClick={() =>
                                    downloadFile(file.filename, file.filePath)
                                  }
                                >
                                  {file.filename}
                                </Typography>
                              </div>
                            );
                          })
                        : "-"}
                    </StyledBody>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <>데이터가 없습니다.</>;
  }
}

export default InternComplete;
