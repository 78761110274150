import { styled, Typography } from "@mui/material";
import React from "react";

const HeaderTitleBar = styled("div")(({ theme }) => ({
  borderLeft: "8px solid",
  borderColor: "#3c3c3b",
  paddingLeft: "5px",
  letterSpacing: "-0.5px",
  marginBottom: "1rem",
}));

interface TitleDivProps {
  title: string;
  children?: React.ReactNode;
}

function TitleDiv({ title, children }: TitleDivProps) {
  return (
    <HeaderTitleBar>
      <Typography variant="h6" fontWeight="bold">
        {title}
        {children}
      </Typography>
    </HeaderTitleBar>
  );
}

export default TitleDiv;
