import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Typography } from "@mui/material";
import { ChangeEventArgs } from "@syncfusion/ej2-buttons";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useState } from "react";
import { CustomButton, ValidationText } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { QNA_COMMENT } from "../../../system/Constants";
import { QnaApi } from "../../../system/api/QnaApi";
import FileApi from "../../../system/api/fileApi";
import { InitQnaContent } from "../../../system/types/initObject";
import { QnaContent } from "../../../system/types/type";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface QnaProps {
  parentData: QnaContent;
  commentId: number;
  qnaType: string;
  setQnaType: any;
  setRecommentState: any;
  commentState: string;
  setcommentState: any;
}

function validation(data: QnaContent) {
  if (data.qnaType === "") {
    alert("답변 분류를 선택해 주세요.");
    return false;
  }
  return true;
}

function QnAComment({
  parentData,
  commentId,
  qnaType,
  setQnaType,
  setRecommentState,
  commentState,
  setcommentState,
}: QnaProps) {
  const [nameList, setNameList] = useState<string[]>([]);
  const [fileList, setFileList] = React.useState<File[]>([]);
  const [data, setData] = React.useState<QnaContent>(InitQnaContent);
  var submitcheck = false;

  useEffect(() => {
    if (parentData.secret) {
      setData({
        ...data,
        secret: true,
        parentId: parentData.id,
        depth: parentData.depth + 1,
        qnaCls: parentData.qnaCls,
      });
    } else {
      setData({
        ...data,
        parentId: parentData.id,
        depth: parentData.depth + 1,
        qnaCls: parentData.qnaCls,
      });
    }
    if (commentId) {
      QnaApi.getQnaDetail(commentId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId, parentData, commentState]);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeChecked = (args: ChangeEventArgs) => {
    setData({ ...data, secret: args.checked || false });
  };

  const onCancel = () => {
    setQnaType("view");
    setRecommentState("view");
  };
  const Loading = useLoadingDispatch();
  const onSave = () => {
    // 최종 저장
    if (submitcheck === false) {
      submitcheck = true;
      if (validation(data)) {
        Loading({ type: "LOADING" });
        let promise: Promise<any>;
        if (fileList.length > 0) {
          const formData = new FormData();
          Array.from(fileList).forEach((el) => {
            formData.append("uploadFiles", el);
          });
          FileApi.upload(formData)
            .then((file) => {
              if (data.id === 0) {
                promise = QnaApi.createQna({
                  ...data,
                  attachmentFiles: data.attachmentFiles.concat(file),
                });
              } else {
                promise = QnaApi.updateQna({
                  ...data,
                  attachmentFiles: data.attachmentFiles.concat(file),
                });
              }
              promise
                .then(() => {
                  alert("정상적으로 등록되었습니다.");
                  setData(InitQnaContent);
                  setNameList([]);
                  setFileList([]);
                  setQnaType("view");
                  setcommentState(new Date());
                  setRecommentState("view");
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  alert(msg);
                });
            })
            .finally(() => Loading({ type: "COMPLETE" }));
        } else {
          if (data.id === 0) {
            promise = QnaApi.createQna({
              ...data,
            });
          } else {
            promise = QnaApi.updateQna({
              ...data,
            });
          }
          promise
            .then((res) => {
              alert("정상적으로 등록되었습니다.");
              setData(InitQnaContent);
              setNameList([]);
              setFileList([]);
              setQnaType("view");
              setcommentState(new Date());
              setRecommentState("view");
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => Loading({ type: "COMPLETE" }));
        }
      }
    }
  };

  const deleteFile = (e: any) => {
    const fileArr = nameList.filter((x, idx) => idx !== e);
    const uploadArr = fileList.filter((x, idx) => idx !== e);
    setNameList([...fileArr]);
    setFileList([...uploadArr]);
  };

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const targetFiles = Array.from(event.target.files);
      const totalSize = targetFiles.reduce((acc, file) => acc + file.size, 0);
      const maxSize = 1024 * 1024 * 1024;
      if (totalSize > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      setFileList(targetFiles);
      const selectedFile: string[] = [];
      targetFiles.map((file) => {
        return selectedFile.push(file.name);
      });
      setNameList(selectedFile);
    }
  };

  const deleteExistFile = (idx: number) => {
    if (
      window.confirm(
        "파일을 정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다."
      )
    ) {
      setData({
        ...data,
        attachmentFiles: data.attachmentFiles.filter((x, i) => idx !== i),
      });
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Typography variant="body1">답변 분류</Typography>
      </Grid>
      <Grid item xs={3}>
        <DropDownListComponent
          value={data.qnaType}
          name="qnaType"
          cssClass="e-outline"
          placeholder="선택"
          dataSource={QNA_COMMENT}
          onChange={onChange}
        ></DropDownListComponent>
        {data.qnaType.length < 1 ? (
          <ValidationText>
            <span className="e-icons e-circle-check"></span> 답변 분류가
            입력되어 있지 않습니다.
          </ValidationText>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">내용</Typography>
      </Grid>
      <Grid item xs={12}>
        <textarea
          style={{ width: "100%", height: "200px", resize: "none" }}
          name="content"
          value={data.content}
          placeholder="내용을 입력해주세요."
          onChange={onChange}
        ></textarea>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">첨부파일</Typography>
      </Grid>
      <Grid item xs={12}>
        {data.id !== 0 && (
          <Grid item xs={12} style={{ margin: "10px 0px" }}>
            기존파일 :{" "}
            {data.attachmentFiles &&
              data.attachmentFiles.map((x, idx) => (
                <span
                  key={x.filename + idx}
                  style={{ fontSize: "14px", margin: "3px" }}
                >
                  {x.filename}
                  <ButtonComponent
                    style={{ marginLeft: "3px", fontSize: "14px" }}
                    cssClass="e-small e-danger"
                    onClick={() => deleteExistFile(idx)}
                  >
                    삭제
                  </ButtonComponent>
                </span>
              ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <input id="file" type="file" onChange={onSaveFile} multiple></input>
          <br />
          <div>
            {nameList &&
              nameList.map((e, idx) => (
                <div key={idx} style={{ fontSize: "14px" }}>
                  {e}
                  <IconButton
                    size="small"
                    style={{ padding: "0px" }}
                    onClick={() => deleteFile(idx)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="text-end">
          {parentData.secret === true ? (
            <CheckBoxComponent
              label="비밀글"
              name="secret"
              value="secret"
              checked={true}
              disabled
            />
          ) : (
            <CheckBoxComponent
              label="비밀글"
              name="secret"
              value="secret"
              change={onChangeChecked}
              checked={data.secret || false}
            />
          )}

          <CustomButton
            style={{ marginLeft: "5px" }}
            onClick={onSave}
            iconCss="e-icons e-save"
          >
            저장
          </CustomButton>
          {qnaType === "view" ? (
            <CustomButton
              style={{ marginLeft: "5px" }}
              onClick={onCancel}
              iconCss="e-icons e-delete-1"
            >
              취소
            </CustomButton>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default QnAComment;
