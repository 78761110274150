export const defaultReportValue =
  // eslint-disable-next-line no-multi-str
  '<table id="namo-editor-report-table" name="namo-editor-report-table" cellspacing="0" cellpadding="5" border="1" style="width: 100%; font-size:10pt; border-width: 0px; border-color:#000000; border-collapse:collapse; border-style: solid; background-color: #FFFFFF "> \
<tbody> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">결과 요약</span></td> \
</tr> \
<td style="width: 100%; height: 150px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; background-color: #F0F2F5; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
<span style="font-weight: bold; font-size: 14px;">평가지표(기획서에 작성한 평가지표를 쓰고, 각 지표에 대한 결과 내용을 작성해주시기 바랍니다.)</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 150px; border-width: 1px; border-color: #e0e0e0; border-style: solid;"> \
</td> \
</tr> \
</tbody> \
</table>';
