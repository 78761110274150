import { Grid, Pagination, Typography } from "@mui/material";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import {
  Column,
  ColumnDirective,
  ColumnsDirective,
  IFilterUI,
} from "@syncfusion/ej2-react-grids/src";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../../styles/table.css";
import { ErrorHandler } from "../../../system/ApiService";
import { CommentApi } from "../../../system/api/Comment";
import {
  AsmtWorkRoutes,
  NoteList,
  ReportRoutes,
} from "../../../system/types/type";
import { DropDownList } from "@syncfusion/ej2-react-dropdowns";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { useRecoilState } from "recoil";
import { adminCommentState, adminNoteTypeState } from "../../../system/atoms";
import { StyledListComponent } from "../../../styles/theme";

function NoteAdminTable() {
  const history = useHistory();
  const Loading = useLoadingDispatch();
  const [data, setData] = useState<NoteList>();
  const [page, setPage] = useRecoilState(adminCommentState);
  const [isWorkType, setIsWorkType] = useRecoilState(adminNoteTypeState);

  useEffect(() => {
    Loading({ type: "LOADING" });
    CommentApi.ConfirmCommentList(isWorkType, page, 10)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  }, [page, isWorkType]);

  const onPageChange = (args: any, value: number) => {
    setPage(value - 1);
  };

  let grid: any;
  const rowSelected = (props: any) => {
    if (grid) {
      if (props.data.classification === "인턴") {
        const selectedrecords = grid.getSelectedRecords();
        history.push({
          pathname: `${AsmtWorkRoutes.root}/intern/detail/${selectedrecords[0].workId}`,
        });
      } else if (props.data.classification === "보고서") {
        const selectedrecords = grid.getSelectedRecords();
        history.push({
          pathname: `${ReportRoutes.root}/detail/${selectedrecords[0].workId}`,
        });
      } else {
        const selectedrecords = grid.getSelectedRecords();
        history.push({
          pathname: `${AsmtWorkRoutes.root}/detail/${selectedrecords[0].workId}`,
        });
      }
    }
  };

  const examine = useCallback((props: any): any => {
    return <>{props.examine ? "확인요청" : "-"}</>;
  }, []);

  const confirm = useCallback((props: any): any => {
    return <>{props.confirm ? "완료" : "진행중"}</>;
  }, []);
  const templateOptions: IFilterUI = {
    create: (args: { element: Element; column: Column }) => {
      const dd = document.createElement("input");
      dd.id = "confirm";
      return dd;
    },
    write: (args: { element: Element; column: Column }) => {
      let DropDownListObj: DropDownList = new DropDownList({
        dataSource: ["전체", "진행중", "완료"],
        fields: { text: "confirm", value: "confirm" },
        change: function (e) {
          let gridObj = (document.getElementsByClassName("e-grid")[0] as any)
            .ej2_instances[0];
          if (e.value === "전체") {
            gridObj.removeFilteredColsByField("confirm");
          } else {
            gridObj.filterByColumn(
              "confirm",
              "equal",
              e.value === "완료" ? "true" : "false"
            );
          }
        },
      });
      DropDownListObj.appendTo("#confirm");
    },
  };
  const SearchNote = ["과제", "문서"];

  const onChange = (args: any) => {
    if (args.value === "문서") {
      setPage(0);
      setIsWorkType(false);
    } else {
      setPage(0);
      setIsWorkType(true);
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <StyledListComponent
          value={isWorkType ? "과제" : "문서"}
          cssClass="e-outline"
          dataSource={SearchNote}
          onChange={onChange}
        ></StyledListComponent>
      </Grid>
      <Grid item xs={12}>
        <GridComponent
          dataSource={data?.comments}
          rowSelected={rowSelected}
          ref={(g) => (grid = g)}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="classification"
              headerText="분류"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="title"
              headerText="과제명"
              headerTextAlign="Center"
              width="300"
            />
            <ColumnDirective
              field="createdBy"
              headerText="작성자"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="examine"
              headerText="확인요청"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
              template={examine}
            />
            <ColumnDirective
              field="dueDate"
              headerText="확인기한"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="confirm"
              headerText="확인완료"
              headerTextAlign="Center"
              textAlign="Center"
              width="100"
              template={confirm}
              filterBarTemplate={templateOptions}
            />
          </ColumnsDirective>
        </GridComponent>
      </Grid>
      <Grid item xs={6}>
        <Pagination
          count={data?.totalPages}
          page={page + 1}
          onChange={onPageChange}
          showFirstButton
          showLastButton
          defaultPage={10}
          boundaryCount={2}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign={"right"}>
          <span>
            {page + 1} of {data?.totalPages} pages
          </span>
          <span>({data?.totalElements} items)</span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default NoteAdminTable;
