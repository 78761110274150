import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";
import { ChangeEventArgs } from "@syncfusion/ej2-buttons";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { createRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { CustomButton, ValidationText } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import { QNA_CLASSIFICATION } from "../../../system/Constants";
import { QnaApi } from "../../../system/api/QnaApi";
import FileApi from "../../../system/api/fileApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { InitQnaContent } from "../../../system/types/initObject";
import { QnaContent, QnaRoutes } from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import TitleDiv from "../../Common/TitleDiv";

interface Params {
  id: string;
}

function validation(data: QnaContent) {
  if (data.qnaCls === "") {
    alert("문의 분류를 선택해 주세요.");
    return false;
  } else if (data.title === "") {
    alert("과제명이 입력되어 있지 않습니다.");
    return false;
  }
  return true;
}

function QnAEdit() {
  const { id } = useParams<Params>();
  const qnaId = parseInt(id);
  const [nameList, setNameList] = useState<string[]>([]);
  const [fileList, setFileList] = useState<File[]>([]);
  const refContent = createRef<CrossEditor>();
  const Loading = useLoadingDispatch();
  const [data, setData] = useState<QnaContent>(InitQnaContent);
  var submitcheck = false;
  const history = useHistory();
  const [started, setStarted] = useState<boolean>(false);

  useEffect(() => {
    if (qnaId) {
      QnaApi.getQnaDetail(qnaId)
        .then((res) => {
          setData({ ...res.data, setInfo: true });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      setData({ ...InitQnaContent, setInfo: true });
    }
  }, [qnaId]);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeChecked = (args: ChangeEventArgs): void => {
    setData({ ...data, secret: args.checked || false });
  };

  const onSave = () => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }
    // 최종 저장
    if (submitcheck === false && refContent.current) {
      const editor = refContent.current.editorRef;

      submitcheck = true;
      if (validation(data)) {
        Loading({ type: "LOADING" });
        let promise: Promise<any>;
        if (fileList.length > 0) {
          const formData = new FormData();
          Array.from(fileList).forEach((el) => {
            formData.append("uploadFiles", el);
          });
          FileApi.upload(formData)
            .then((file) => {
              if (data.id === 0) {
                promise = QnaApi.createQna({
                  ...data,
                  attachmentFiles: data.attachmentFiles.concat(file),
                  content: editor.GetBodyValue() || "",
                  qnaType: "질문",
                });
              } else {
                promise = QnaApi.updateQna({
                  ...data,
                  attachmentFiles: data.attachmentFiles.concat(file),
                  content: editor.GetBodyValue() || "",
                  qnaType: "질문",
                });
              }
              promise
                .then(() => {
                  alert("정상적으로 등록되었습니다.");
                  history.push(`${QnaRoutes.root}`);
                })
                .catch((err) => {
                  let msg = ErrorHandler(err);
                  alert(msg);
                });
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => Loading({ type: "COMPLETE" }));
        } else {
          if (data.id === 0) {
            promise = QnaApi.createQna({
              ...data,

              content: editor.GetBodyValue() || "",
              qnaType: "질문",
            });
          } else {
            promise = QnaApi.updateQna({
              ...data,
              content: editor.GetBodyValue() || "",
              qnaType: "질문",
            });
          }
          promise
            .then(() => {
              alert("정상적으로 등록되었습니다.");
              history.push(`${QnaRoutes.root}`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => Loading({ type: "COMPLETE" }));
        }
      }
    }
  };

  const deleteFile = (e: any) => {
    const fileArr = nameList.filter((x, idx) => idx !== e);
    const uploadArr = fileList.filter((x, idx) => idx !== e);
    setNameList([...fileArr]);
    setFileList([...uploadArr]);
  };

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const targetFiles = Array.from(event.target.files);
      const totalSize = targetFiles.reduce((acc, file) => acc + file.size, 0);
      const maxSize = 1024 * 1024 * 1024;
      if (totalSize > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      setFileList(targetFiles);
      const selectedFile: string[] = [];
      targetFiles.map((file) => {
        return selectedFile.push(file.name);
      });
      setNameList(selectedFile);
    }
  };

  const deleteExistFile = (idx: number) => {
    if (
      window.confirm(
        "파일을 정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다."
      )
    ) {
      setData({
        ...data,
        attachmentFiles: data.attachmentFiles.filter((x, i) => idx !== i),
      });
    }
  };

  const params = {
    Width: "100%",
    Height: 800,
  };

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="문의하기(QnA)"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <div>문의 분류</div>
      </Grid>
      <Grid item xs={3}>
        <DropDownListComponent
          value={data.qnaCls}
          name="qnaCls"
          cssClass="e-outline"
          placeholder="선택"
          dataSource={QNA_CLASSIFICATION}
          onChange={onChange}
        ></DropDownListComponent>
        {data.qnaCls.length < 1 ? (
          <ValidationText>
            <span className="e-icons e-circle-check"></span> 문의 분류가
            입력되어 있지 않습니다.
          </ValidationText>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <div>제목</div>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          value={data.title}
          name="title"
          placeholder="제목을 입력하세요."
          onChange={onChange}
          style={{ height: "48px" }}
          cssClass="e-outline"
        ></TextBoxComponent>
        {data.title.length < 1 ? (
          <ValidationText>
            <span className="e-icons e-circle-check"></span> 제목이 입력되어
            있지 않습니다.
          </ValidationText>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <div>내용</div>
      </Grid>
      <Grid item xs={12}>
        {data.setInfo && (
          <CrossEditor
            ref={refContent}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <div>첨부파일</div>
      </Grid>
      <Grid item xs={12}>
        {data.id !== 0 && (
          <Grid item xs={12} style={{ margin: "10px 0px" }}>
            기존파일 :{" "}
            {data.attachmentFiles &&
              data.attachmentFiles.map((x, idx) => (
                <span
                  key={x.filename + idx}
                  style={{ fontSize: "14px", margin: "3px" }}
                >
                  {x.filename}
                  <ButtonComponent
                    style={{ marginLeft: "3px", fontSize: "14px" }}
                    cssClass="e-small e-danger"
                    onClick={() => deleteExistFile(idx)}
                  >
                    삭제
                  </ButtonComponent>
                </span>
              ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <input id="file" type="file" onChange={onSaveFile} multiple></input>
          <br />
          <div>
            {nameList &&
              nameList.map((e, idx) => (
                <div key={idx} style={{ fontSize: "14px" }}>
                  {e}
                  <IconButton
                    size="small"
                    style={{ padding: "0px" }}
                    onClick={() => deleteFile(idx)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="text-end">
          <CheckBoxComponent
            label="비밀글로 문의하기"
            name="secret"
            value="secret"
            change={onChangeChecked}
            checked={data.secret}
          />
          <CustomButton
            style={{ marginLeft: "5px" }}
            onClick={onSave}
            iconCss="e-icons e-save"
          >
            저장
          </CustomButton>
        </div>
      </Grid>
    </Grid>
  );
}

export default QnAEdit;
