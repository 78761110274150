import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DailyApi } from "../../system/api/DailyApi";
import { ErrorHandler } from "../../system/ApiService";
import { dailyWorkWrite } from "../../system/types/dailyWork";
import { InitDaily } from "../../system/types/initObject";
import { DailyWorkRoutes } from "../../system/types/type";
import TitleDiv from "../Common/TitleDiv";

// mui
import { Grid } from "@mui/material";

// syncfusion
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import {
  CustomButton,
  DailyWriteTextarea,
  DatePickerCustom,
} from "../../styles/theme";
import "../../styles/scrollbar.css";
import { useUserState } from "../../system/context/UserContext";

interface Params {
  id: string;
}

function DailyEdit() {
  const { id } = useParams<Params>();
  const dailyId = parseInt(id);
  const history = useHistory();
  const location = useLocation<string>();
  const selectDay = location.state;
  const [data, setData] = useState<dailyWorkWrite>(InitDaily);
  const user = useUserState();

  useEffect(() => {
    if (dailyId) {
      DailyApi.getDailyWorkDetail(dailyId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      DailyApi.getDailyExist(selectDay).then((res) => {
        if (res.data) {
          alert(
            `${selectDay} 일일업무보고는 이미 작성하셨습니다. \n수정을 원하시는 경우 해당글에서 수정 부탁드립니다.`
          );
          return;
        } else {
          setData({ ...data, workDate: selectDay });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyId, selectDay]);

  const handleOnChange = (e: { target: { value: string; name: string } }) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const OnChange = (args: any) => {
    if (args.target.name === "workDate") {
      let newDate: string = moment(args.target.value).format("YYYY-MM-DD");
      DailyApi.getDailyExist(newDate).then((res) => {
        if (res.data) {
          alert(
            `${newDate} 일일업무보고는 이미 작성하셨습니다. \n수정을 원하시는 경우 해당글에서 수정 부탁드립니다.`
          );
          return;
        } else {
          setData({
            ...data,
            [(args.target as HTMLInputElement).name]: moment(
              args.target.value
            ).format("YYYY-MM-DD"),
          });
        }
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  // 저장
  function onSave() {
    if (data) {
      if (data.todayWorks.length === 0) {
        alert("오늘 할 일을 작성해주세요.");
        return;
      }

      if (data.tomorrowWorks.length === 0) {
        alert("내일 할 일을 작성해주세요.");
        return;
      }
    }
    if (dailyId) {
      DailyApi.updateDailyWork({
        id: dailyId,
        issue: data.issue,
        todayWorks: data.todayWorks,
        tomorrowWorks: data.tomorrowWorks,
      })
        .then(() => {
          alert("일일업무 보고가 수정되었습니다.");
          history.push({
            pathname: `${DailyWorkRoutes.root}`,
          });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      DailyApi.createDailyWork(data)
        .then(() => {
          alert("일일업무 보고가 작성되었습니다.");
          history.push("/daily/work");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
          alert(err.response.data.message);
        });
    }
  }

  let datePick: any;

  // css
  const topStyle = {
    borderTop: "2px solid #B3BBC5",
    borderBottom: "2px solid #B3BBC5",
    padding: "10px",
    backgroundColor: "#F8F8F8",
    margin: "10px 0px",
    alignItems: "center",
  };

  const userInfo = {
    padding: "0px",
  };

  const item = {
    borderLeft: "2px solid #B3BBC5",
    padding: "5px 10px",
  };

  const date = {
    borderLeft: "2px solid #B3BBC5",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
  };

  const textBoxTitle = {
    width: "110px",
    fontWeight: "bold",
  };

  const textBox = {
    borderBottom: "2px solid #B3BBC5",
    margin: "10px 30px",
    height: "230px",
    display: "flex",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TitleDiv title=" 일일업무 보고 수정"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={topStyle}>
          <Grid item xs={4} style={userInfo}>
            성명 : {user.name}
          </Grid>
          <Grid item xs={dailyId ? 5 : 4} style={item}>
            소속 : {user.team}
          </Grid>
          {dailyId ? (
            <Grid item xs={3} style={item}>
              날짜 : {data.workDate}
            </Grid>
          ) : (
            <Grid item xs={4} style={date}>
              <div style={{ marginRight: "10px" }}>날짜</div>
              <DatePickerCustom>
                <DatePickerComponent
                  placeholder="일일업무 보고 날짜를 입력해 주세요."
                  name="workDate"
                  format="yyyy-MM-dd"
                  cssClass="e-outline e-custom"
                  value={new Date(data.workDate)}
                  onChange={OnChange}
                  focus={(e) => datePick.show()}
                  ref={(scope) => {
                    datePick = scope;
                  }}
                />
              </DatePickerCustom>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} style={textBox}>
        <div style={textBoxTitle}>오늘 할 일</div>
        <DailyWriteTextarea
          name="todayWorks"
          value={data.todayWorks}
          onChange={OnChange}
        ></DailyWriteTextarea>
      </Grid>
      <Grid item xs={12} style={textBox}>
        <div style={textBoxTitle}>이슈</div>

        <DailyWriteTextarea
          placeholder="일일보고 목록에서 제목처럼 확인할 수 있는 칸입니다. 추후 진행한 업무에 대해 쉽게 확인할 수 있는 키워드를 적어주시면 되며 빈칸으로 두셔도 됩니다."
          name="issue"
          value={data.issue}
          onChange={handleOnChange}
        ></DailyWriteTextarea>
      </Grid>
      <Grid item xs={12} style={textBox}>
        <div style={textBoxTitle}> 내일 할 일</div>
        <DailyWriteTextarea
          name="tomorrowWorks"
          value={data.tomorrowWorks}
          onChange={handleOnChange}
        ></DailyWriteTextarea>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right", margin: "30px" }}>
        <CustomButton iconCss="e-icons e-check" onClick={onSave}>
          저장
        </CustomButton>
      </Grid>
    </Grid>
  );
}

export default DailyEdit;
