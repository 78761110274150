import { AxiosError } from "axios";
import HttpClient from "./http-client";

//TODO: env로 변경할 것
const url: string | undefined = `${process.env.REACT_APP_API_SERVER_URI}`;
// const url: string|undefined = 'http://192.168.45.111:8787/api';
const client = new HttpClient(url);

export default client;

function isErrorWithMessage(error: unknown): string {
  if (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  ) {
    return (error as Record<string, unknown>).message as string;
  } else {
    return "";
  }
}

export function ErrorHandler(error: AxiosError): string {
  let msg = "";
  if (error.response) {
    // msg += error.response.data;
    msg = isErrorWithMessage(error.response.data);
    if (msg === "" || msg === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          msg = "세션이 만료되었습니다.";
          break;
        case 400: //Bad Request
          msg = "데이터 입력이 잘못되었습니다.";
          break;
        case 403: //Forbidden
          msg = "접근할 수 없습니다.";
          break;
        case 404: //NotFound
          msg = "표시할 데이터가 없습니다.";
          break;
        case 405: //method not allowd
          msg = "405: 접근할 수 없습니다.";
          break;
        case 413: //Request Entity Too Large
          msg =
            "요청이 너무 커서 처리할 수 없습니다. 크기를 줄이고 다시 시도해주세요.";
          break;
        case 500:
          msg = "서버 에러가 발생하였습니다. 관리자에게 문의해주세요.";
          break;
        default:
          msg = "알 수 없는 오류입니다. 관리자에게 문의해주세요.";
          break;
      }
    }
  } else {
    alert(msg);
    //  msg += "서버에서 데이터를 가져올 수 없습니다.";
  }
  return msg;
}
