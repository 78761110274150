import { Chip } from "@mui/material";

interface StatusChipProps {
  status: string;
  subStatus: string;
}

function StatusChip({ status, subStatus }: StatusChipProps) {
  return (
    <>
      {status === "임시저장" ? (
        <>
          {subStatus ? (
            <Chip
              label={`${status}-${subStatus}`}
              size="small"
              variant="outlined"
              color="secondary"
            />
          ) : (
            <Chip
              label={status}
              size="small"
              variant="outlined"
              color="secondary"
            />
          )}
        </>
      ) : null}
      {status === "등록대기" ? (
        <>
          {subStatus ? (
            <Chip
              label={`${status}-${subStatus}`}
              size="small"
              variant="outlined"
              color="warning"
            />
          ) : (
            <Chip
              label={status}
              size="small"
              variant="outlined"
              color="warning"
            />
          )}
        </>
      ) : null}
      {status === "진행" ? (
        <>
          {subStatus ? (
            <Chip
              label={`${status}-${subStatus}`}
              size="small"
              variant="outlined"
              color="primary"
            />
          ) : (
            <Chip
              label={status}
              size="small"
              variant="outlined"
              color="primary"
            />
          )}
        </>
      ) : null}
      {status === "완료" ? (
        <>
          {subStatus ? (
            <Chip
              label={`${status}-${subStatus}`}
              size="small"
              variant="outlined"
              color="success"
            />
          ) : (
            <Chip
              label={status}
              size="small"
              variant="outlined"
              color="success"
            />
          )}
        </>
      ) : null}
      {status === "중단" ? (
        <>
          {subStatus ? (
            <Chip
              label={`${status}-${subStatus}`}
              size="small"
              variant="outlined"
              color="error"
            />
          ) : (
            <Chip
              label={status}
              size="small"
              variant="outlined"
              color="error"
            />
          )}
        </>
      ) : null}
    </>
  );
}

export default StatusChip;
