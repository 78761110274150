import { Grid } from "@mui/material";
import NonPctDetail from "../../../components/Admin/Statistics/NonPctDetail";

interface Props {
  handleOpen: (open : boolean) => void;
}
function StatisticsNonpct({handleOpen}:Props) {
  handleOpen(false);
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");
  const department = urlParams.get("department");
  const team = urlParams.get("team");
  const participationType = urlParams.get("participationType");
  return (
    <>
      {year ? (
        <Grid item xs={12}>
          <NonPctDetail
            year={year}
            department={department}
            team={team}
            participationType={participationType}
          ></NonPctDetail>
        </Grid>
      ) : null}
    </>
  );
}

export default StatisticsNonpct;
